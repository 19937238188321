<template>
  <form>
    <div  class="importForm-container row">
      <div>
        <InfoBox v-if="value['importTypeToSend']">
          <span>{{ getInfoText }}</span>
        </InfoBox>
      </div>
      <div class="select-importType-wrapper">
        <select-type
          v-model="value['importTypeToSend']"
          class="col-xs-12"
          name="importType"
          label="Rodzaj faktury"
          :required="true"
          :options="importTypeOptions"
          :empty-first="false"
        />
      </div>
      <div class="file-input-container">
        <button @click="clickInput" class="zord-btn btn zord-btn-primary btn-primary select-file-button" :class="{'btn-success': file.name}">Wybierz plik</button>
        <label class="file-input-label">
          <input
            style="display: none"
            id="file"
            ref="file"
            type="file"
            @change="handleFileUpload"
          >
          <span v-if="file">{{file.name}}</span>
        </label>
      </div>
    </div>
  </form>
</template>

<script>
  import FormModalValidation from '../../../../share/form/FormModalValidation'
  import Loader from '../../../../share/Loader'
  import InfoBox from '../../../../share/InfoText/InfoBox'
import SelectType from '../../../../../components/share/form/type/SelectType.vue'
  export default {
    components: {
      InfoBox,
      SelectType
    },
    mixins: [
      FormModalValidation,
      Loader
    ],
    props: {
      value: {}
    },
    data () {
      return {
        importTypes: [],
        info: {},
        file: {},
        importTypeOptions: [
          {label: 'Faktury kosztowe', value: 'appraisal'},
          {label: 'Faktury przychodowe', value: 'main'} ]
      }
    },
    computed: {
      getInfoText () {
          if (this.value.importTypeToSend === 'main' ) {
            return `Zestawienie musi zawierać datę wystawienia w kolumnie "B", numer zlecenia (MLN) w kolumnie "F", 
            kwotę w kolumnie "H" oraz numer FV w kolumnie "M".`
          }  else  (this.value.importTypeToSend === 'appraisal') 
          { return ` Zestawienie musi zawierać numer zlecenia w kolumnie "A" oraz nową kwotę faktury w kolumnie "B".`}
      }
    },
    methods: {
      clickInput(e) {
        e.preventDefault()
        this.$refs.file.click()
      },
      handleFileUpload (e) {
        if (this.$refs.file.files[0]) {
          this.value.file = this.$refs.file.files[0]
          this.file = this.$refs.file.files[0]
        } else {
          this.value.file = {}
          this.file = {}
        }

      }
    }
  }
</script>
<style scoped>
.importForm-container{
  display: flex;
  flex-direction: column;
  padding: 0 20px
}
  .select-file-button {
    width: 100px;
    height: 30px;
    margin: 5px;
  }
  .file-input-container {
    display: flex;
  }
  .file-input-label {
    padding: 10px 0 0 0;
  }
</style>
