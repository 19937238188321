<script>
import FormModalMixin from '../../../../share/modal/FormModalMixin'
import FormModal from '../form/SpreadsheetImport'
import api from '../../../../../api'
import WebStorage from '../../../../share/WebStorage'
import clientObjectMixin from '../../share/mixins/clientObjectMixin'

export default {
  components: {
    FormModal
  },
  mixins: [
    FormModalMixin,
    clientObjectMixin

  ],
  data () {
    return {
      title: 'Importuj Plik',
      okText: 'Importuj',
    }
  },
  computed: {
    workflowName () {
    return this.$getCategoryNew(this.clientObject, this.$route.params.taskCategoryId).workflows[0].name
    },
  },
  methods: {
    redirectProperly () {
      this.$router.push({ name: `${this.service}_finance_import_invoice_${this.model.importTypeToSend}` })
    },
    submitForm () {
      WebStorage.setImportSpreadsheet({})
      api.request(this.service, 'post', `/finance-import${this.$isWithClients(this.service) ? `?client=${this.$route.meta.client ?? this.$route.params.client}` : ''}`, this.createDataToSend(), this.prepareAdditionalHeaders())
        .then((response) => {
      WebStorage.setImportSpreadsheet(response.data)
          this.redirectProperly()
          this.$notify({
            type: 'success',
            text: 'Wczytano plik'
          })
        })
        .catch((error) => {
          this.errorNotify(error)
        })
    },
    createDataToSend () {
      let formData = new FormData()
      formData.append('spreadsheetFile', this.model.file)

      formData.append('importType', this.model.importTypeToSend)
      return formData
    },
    prepareAdditionalHeaders () {
      return [
        { 'Content-Type': 'multipart/form-data' }
      ]
    }
  }
}
</script>
