import { jsPDF } from "jspdf";
import { zordonLogo } from "./assets/zordonLogo.blob";
import { partnerLogo } from "./assets/partnerLogo.blob.js";
import "./assets/OpenSans-Regular-normal.js";
import "./assets/OpenSans-Bold-normal.js";

function printFieldValue(value) {
  return value || 'b.d.'
}

function calcEmptySpace(doc,text, textWidth) {
	return Math.round(doc.splitTextToSize(text, textWidth).length * 4)
}

export const configPdfview = (data) => {
	const doc = new jsPDF('p','mm','a4', true); 
  
  doc.addFont("OpenSans-Regular-normal.ttf", "OpenSans-Regular-normal", "normal"); 
  doc.addFont("OpenSans-Bold-normal.ttf", "OpenSans-Bold-normal", "bold"); 

  let pageMargin = 0;
	let lineCounter = 0;
	let leftSide = 10;
	let rightSide = 135;
	let marginLeft = 12;
	let fontNormal = "OpenSans-Regular-normal"
	let fontBold = "OpenSans-Bold-normal"
	let fontSizeM = 11
	let fontSizeS = 10

	function counter (c = 8) {
		lineCounter = lineCounter + c;
		return lineCounter;
	}

  data.forEach((item, ind) => {
    doc.addImage(`data:image/png;base64,${partnerLogo}`, "PNG", marginLeft, counter(16), 45, 10);
    
    doc.setFontSize(8);
		doc.setFont(fontNormal, "normal");
    doc.text("Pobrano z systemu", 134, counter(4));

    doc.addImage(`data:image/png;base64,${zordonLogo}`, "PNG", 165, counter(-4), 36, 10);

    // Nr zlecenia
		doc.setFontSize(fontSizeM);
		doc.setFont(fontBold, "bold");
		doc.text("Numer zlecenia: " + item.number, rightSide, counter(22));
		doc.text("Numer banku: " + item.order_id, rightSide, counter());
		
		// Dane klienta
		doc.setFontSize(fontSizeM);
		doc.setFont(fontBold, "bold");
		doc.text("Dane klienta:", leftSide, counter());

		doc.setFontSize(fontSizeS);
		doc.setFont(fontNormal, "normal");
		doc.text("Imię i Nazwisko: " + printFieldValue(item.forename) + ' ' + printFieldValue(item.surname), marginLeft, counter());

		// Dane zlecenia
		doc.setFontSize(fontSizeM);
		doc.setFont(fontBold, "bold");
		doc.text("Dane zlecenia:", leftSide, counter(10));

		doc.setFontSize(fontSizeS);
		doc.setFont(fontNormal, "normal");
		doc.text("Data przyjęcia: " + printFieldValue(item.initial_date), marginLeft, counter());
		doc.text("Termin realizacji: " + printFieldValue(item.local_vision_appointed_at), marginLeft, counter());

		// Adres nieruchomości
		doc.setFontSize(fontSizeM);
		doc.setFont(fontBold, "bold");
		doc.text("Adres nieruchomości:", leftSide, counter(10));

    doc.setFontSize(fontSizeS);
		doc.setFont(fontNormal, "normal");

    item.address.forEach((el, index) => {
			let marginLeftInternal = marginLeft

			if (index > 0) {
				marginLeftInternal = marginLeft + index * 70
			}

      doc.text(`Adres: ${index + 1}`, marginLeftInternal, counter(index > 0 ? -72 : 8));
      doc.text("Województwo: " + printFieldValue(el.voivodeship), marginLeftInternal, counter());
      doc.text("Powiat: " + printFieldValue(el.county), marginLeftInternal, counter());
      doc.text("Gmiana: " + printFieldValue(el.municipality), marginLeftInternal, counter());
      doc.text("Miejscowość: " + printFieldValue(el.place), marginLeftInternal, counter());
      doc.text("Ulica: " + printFieldValue(el.street), marginLeftInternal, counter());
      doc.text("Numer budynku: " + printFieldValue(el.building_number), marginLeftInternal, counter());
      doc.text("Numer lokalu: " + printFieldValue(el.flat_number), marginLeftInternal, counter());
      doc.text("Dzielnica: " + printFieldValue(el.district), marginLeftInternal, counter());
      doc.text("Kod pocztowy: " + printFieldValue(el.postcode), marginLeftInternal, counter());
    })

		// Typ nieruchomości
		doc.setFontSize(fontSizeM);
		doc.setFont(fontBold, "bold");
		doc.text("Typ nieruchomości:", leftSide, counter(10));

		doc.setFontSize(fontSizeS);
		doc.setFont(fontNormal, "normal");

    if( item.kind === null) {
      doc.text('b.d', marginLeft, counter());

    } else {
      if (item.kind?.includes('Residential')) {
        doc.text('Lokal mieszkalny', marginLeft, counter())
      } 
      if (item.kind?.includes('Garage')) {
        doc.text('Garaż', marginLeft, counter());
      } 
      if (item.kind?.includes('House')) {
        doc.text('Budynek mieszkalny jednorodzinny', marginLeft, counter());
      } 
      if (item.kind?.includes('Plot')) {
        doc.text('Grunt niezabudowany', marginLeft, counter());
      } 
      if (item.kind?.includes('OnlyParkingSpace')) {
        doc.text('Miejsce postojowe', marginLeft, counter());
      } 
    }

		// Cel kredytowania
		doc.setFontSize(fontSizeM);
		doc.setFont(fontBold, "bold");
		doc.text("Cel kredytowania:", leftSide, counter(10));

		doc.setFontSize(fontSizeS);
		doc.setFont(fontNormal, "normal");
		doc.text(printFieldValue(item.credit_purpose), marginLeft, counter(), { maxWidth: 186 });

		// Uwagi
		doc.setFontSize(fontSizeM);
		doc.setFont(fontBold, "bold");
		doc.text("Uwagi:", leftSide, counter(10));

		doc.setFontSize(fontSizeS);
		doc.setFont(fontNormal, "normal");
		
		doc.text(printFieldValue(item.remarks), marginLeft, counter(), { maxWidth: 186 });
		doc.text('', marginLeft, counter(calcEmptySpace(doc, printFieldValue(item.remarks), 186)))

		// Dane Asystenta BWN
		doc.setFontSize(fontSizeM);
		doc.setFont(fontBold, "bold");
		if (lineCounter >= 257) {
			lineCounter = pageMargin
			doc.addPage()
		}

		doc.text("Dane Asystenta BWN:", leftSide, counter(10))
		doc.setFontSize(fontSizeS);
		doc.setFont(fontNormal, "normal");
		doc.text("Asystent: " + printFieldValue(item.assistant.forename) + " " + printFieldValue(item.assistant.surname), marginLeft, counter());
		doc.text("Adres email: " + printFieldValue(item.assistant.email), marginLeft, counter());
		doc.text("Numer telefonu: " + printFieldValue(item.assistant.phone), marginLeft, counter());

		if (ind !== data.length - 1) {
			lineCounter = pageMargin
			doc.addPage();
		}
	})
  
	doc.save("zlecenie.pdf");
}