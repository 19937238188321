export default {
  renderHtml (data, type, row, config) {
    let routeTo = config.routeToItems[config.getCategoryGroupFunc(row.categoryId)]
    for (let paramsKey in routeTo.params) {
      if (routeTo.params.hasOwnProperty(paramsKey)) {
        routeTo.params[paramsKey] = row[routeTo.params[paramsKey]]
      }
    }
    const {
      href
    } = config.router().resolve(routeTo)

    let params = this.renderParams(config, row)

    return `<a href="${href}"><button
    class="${config.cssClass !== undefined ? config.cssClass : ''} action-${config.id} btn btn-primary zord-btn zord-btn-primary"
     ref="${data}"
      params='${params}'
       role="button"
        title="${config.title}"
         type="button"
         >
<i class="feather-${config.icon}"></i></button></a>`
  },
  renderParams: function (config, row) {
    // let paramsObj: {[k: string]: any} = {}

    let paramsObj = {}
    if (config.params && config.params.length > 0) {
      config.params.forEach((item) => {
        if (row.hasOwnProperty(item)) {
          paramsObj[`${item}`] = row[item]
        }
      })
    }

    return JSON.stringify(paramsObj)
  }
}
