<template>
  <table :id="name" class="table table-striped table-bordered dataTable" role="grid" width="100%">
    <thead>
    <tr class="head">
      <th v-for="item in columns" :key="item.id" class="custom">{{ item.title }}</th>
      <th/>
    </tr>
    </thead>
    <tr class="searcher" @keyup.enter="search()">
      <template v-for="item in translatedColumns">
        <th
          v-if="item.searchable === false" :key="item.id"
          class="centered-flex-container"
        >
          <button
            class="action-0 btn btn-primary zord-btn zord-btn-primary centered-flex-container"
            @click="clearSearch"
            title="Usuń filtry"
          ><i
            class="feather-x"
            style="color: red; font-weight: bold"
          ></i></button>
        </th>
        <th v-else :key="item.id">
          <input
            v-if="!dateColumnExists(item) && !vehicleTypeColumnExists(item)"
            v-model="searchModel[item.data]"
            class="form-control"
            type="text"
          />
          <div>
            <date-picker-type
              v-if="dateColumnExists(item)"
              id="datepickerFromlist"
              v-model="searchModel[item.data]"
              class="listDatepicker"
              label
              name="Data"
              :error-message="vErrors.first(item.data)"
              :clear-btn="true"
              @input="search"
            />
          </div>
          <div>
            <select-type
              v-if="vehicleTypeColumnExists(item)"
              v-model="searchModel[item.data]"
              label
              name="Rodzaj"
              :error-message="vErrors.first(item.data)"
              :options="[{label: ''}, {label: 'osobowy', value: 1}, {label: 'ciężarowy', value: 2}]"
              @input="search"
            />
          </div>
        </th>
      </template>
      <th v-if="searchable">
        <button id="task-list-search-button" class="zord-btn btn zord-btn-primary btn-primary pull-right"
                @click="search()">
          <i class="feather-search"/>
        </button>
      </th>
    </tr>
    <tbody class="dataTable-list"/>
  </table>
</template>

<script>
import $ from 'jquery'
import _ from 'lodash'
import 'datatables.net'
import 'datatables.net-bs'
import ActionButton from './ActionButtonNew.js'
import config from '../../../../../../public'
import DatePickerType from '../../../../share/form/type/DatePickerType'
import SelectType from '../../../../share/form/type/SelectType'
import {tracing} from '@opencensus/web-core'

export default {
  components: {SelectType, DatePickerType},
  props: {
    clickableRow: {type: Boolean, default: () => true},
    additionalParams: {type: Object, default: null},
    actions: {type: Array, default: null},
    columns: {type: Array, required: true},
    content: {type: Array, required: false, default: () => []},
    source: {
      type: Object, required: false, default: () => {
      }
    },
    dataSourceMethod: {type: String, default: 'get'},
    dom: {
      type: String,
      default:
        'B<\'row\'<\'col-sm-12\'tr>><\'row\'<\'col-sm-6\'l><\'col-sm-6 text-right\'i>><\'row\'<\'col-sm-12\'p>>'
    },
    language: {type: String, default: 'pl'},
    name: {type: String, default: 'grid'},
    defaultOrder: {
      type: Array,
      default () {
        return [[0, 'asc']]
      }
    },
    processing: {type: Boolean, default: true},
    serverSide: {type: Boolean, default: true},
    selectable: {type: Boolean, default: false},
    searchable: {type: Boolean, default: true},
    service: {type: String, default: () => ''},
    pageLength: {type: Number, default: 25},
  },
  data () {
    return {
      events: {
        reloadGrid: 'reloadGrid'
      },
      languageUrl: `/static/trans/dataTables.${this.$i18n.locale}.json`,
      searchModel: {},
      table: null,
      selected: []
    }
  },
  computed: {
    translatedColumns () {
      const vm = this

      let columns = this.columns
      columns = [
        {
          data: 'id',
          searchable: false,
          orderable: false,
          render (data, type, row) {
            return vm.renderActionButtons(data, type, row)
          }
        },
        ...columns
      ].map(column => {
        column.hasOwnProperty('title') ? column.title = vm.$t(column.title) : column.title = ''
        return column
      })
      return columns

    },
    sourceUrl () {
      return this.dataSourceMethod === 'post'
        ? `${config.app.api.baseUrl[this.source.service || this.service]}${
          this.source.url
        }`
        : `${config.app.api.baseUrl[this.source.service || this.service]}${
          this.source.url
        }?format=datatables`
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initDataTable()
      this.setActions()
      this.selectRow()
      this.$events.on(this.events.reloadGrid, () => {
        this.reloadGrid()
      })
    })
  },
  methods: {
    clearSearch() {
      this.searchModel = {}
      let dataToSave = {
        userUuid: this.$store.state.base.user.uuid,
        name: `${this.name}${this.$route.path}`,
        data: {
          searchModel: this.searchModel
        }
      }
      this.$store.dispatch('saveGridState', dataToSave)
      this.search()
    },
    initDataTable () {
      let vm = this
      var token = this.$store.state.base.token
      let dataTableOptions = {
        columns: this.translatedColumns,
        dom: this.dom,
        language: {
          url: this.languageUrl
        },
        order: this.defaultOrder,
        processing: this.processing,
        serverSide: this.serverSide,
        stateSave: true,
        pageLength: this.pageLength,
        stateLoadCallback: function (settings) {
          let data = vm.$store.getters.getGridStateByName(
            vm.$store.state.base.user.uuid,
            `${vm.name}${vm.$route.path}`
          )
          vm.searchModel = data.searchModel || {}
          return data
        },
        stateSaveCallback: function (settings, data) {
          let dataToSave = {
            userUuid: vm.$store.state.base.user.uuid,
            name: `${vm.name}${vm.$route.path}`,
            settings,
            data,
            searchModel: vm.searchModel
          }
          vm.$store.dispatch('saveGridState', dataToSave)
        }
      }
      if (this.dataSourceMethod === 'post') {
        dataTableOptions.ajax = {
          url: this.sourceUrl,
          type: this.dataSourceMethod,
          contentType: 'application/json',
          data: d => {
            if (this.additionalParams) {
              Object.entries(this.additionalParams).forEach(param => {
                d[param[0]] = param[1]
              })
            }
            d.order = d.order.map(el => {
              if (el.column) {
                el.column = el.column - 1
              }
              return el
            })
            return JSON.stringify(d)
          },
          beforeSend: request => {
            request.setRequestHeader('Authorization', token)
            request.setRequestHeader('X-Locale', vm.$i18n.locale)
          },
          complete: () => {
            this.$events.emit(`${this.name}:dataTableLoaded`)
          }
        }
        this.table = $(`#${this.name}`).DataTable(dataTableOptions)
        return
      }
      if (this.serverSide === false) {
        dataTableOptions.data = this.content
        this.table = $(`#${this.name}`).DataTable(dataTableOptions)
        this.$events.emit(`${this.name}:dataTableLoaded`)
      } else {
        let customSpan = {}
        if (
          process.env.NODE_ENV !== 'testing' &&
          process.env.NODE_ENV !== 'development'
        ) {
          customSpan = tracing.tracer.startChildSpan({
            name:
              'datatables' + ' ' + this.source.service + ' ' + this.sourceUrl
          })
        }

        let data = {
          name: this.name
        }
        if (this.additionalParams) {
          Object.entries(this.additionalParams).forEach(param => {
            data[param[0]] = param[1]
          })
        }
        dataTableOptions.ajax = {
          url: this.sourceUrl,
          type: this.dataSourceMethod,
          data: data,
          beforeSend: request => {
            request.setRequestHeader('Authorization', token)
            if (
              process.env.NODE_ENV !== 'testing' &&
              process.env.NODE_ENV !== 'development'
            ) {
              request.setRequestHeader(
                'traceparent',
                `00-${customSpan.spanContext.traceId}-${customSpan.spanContext.spanId}-01`
              )
              request.setRequestHeader(
                'X-Cloud-Trace-Context',
                `00-${customSpan.spanContext.traceId}-${customSpan.spanContext.spanId}-01`
              )
            }
            request.setRequestHeader('X-Locale', vm.$i18n.locale)
          },
          complete: () => {
            this.$events.emit(`${this.name}:dataTableLoaded`)
          }
        }
        this.table = $(`#${this.name}`).DataTable(dataTableOptions)
        if (
          process.env.NODE_ENV !== 'testing' &&
          process.env.NODE_ENV !== 'development'
        ) {
          customSpan.end()
        }
      }
    },
    search () {
      if (this.source.service === 'core' || this.source.service === 'finance') {
        let VueComponentContext = this
        let srch = VueComponentContext => {
          this.table
            .columns()
            .every(function () {
              const columnApiContext = this
              columnApiContext
                .column(columnApiContext.index())
                .search(
                  VueComponentContext.searchModel[VueComponentContext.translatedColumns[columnApiContext.index()].data]
                )
            })
            .draw()
        }
        srch(VueComponentContext)
      } else {
        this.table.search(JSON.stringify(this.searchModel)).draw()
      }
    },
    reloadGrid () {
      if (this.serverSide) {
        this.table.ajax.reload(null, false)
      }
    },
    renderActionButtons (data, type, row) {
      let buttons
      let actions = _.cloneDeep(this.actions)
      if (actions) {
        for (let c = 0; c < actions.length; c++) {
          if (this.$auth.isAllowed(actions[c].acl)) {
            buttons = ActionButton.renderHtml(
              data,
              type,
              row,
              actions[c]
            )
          }
        }
      }
      return buttons
    },
    setActions () {
      if (this.actions) {
        for (let c = 0; c < this.actions.length; c++) {
          const action = this.actions[c]
          $(`#${this.name}.dataTable .dataTable-list`).on(
            'click',
            `a`,
            event => {
              event.preventDefault()
              if (event.ctrlKey || event.metaKey) {
                window.open(event.currentTarget.href, '_blank')
                return
              }
              let buttonParams
              if (event.currentTarget.getElementsByTagName('button')[0]) {
                buttonParams = event.currentTarget
                  .getElementsByTagName('button')[0]
                  .getAttribute('params')

              } else {
                this.$notify({
                  type: 'error',
                  text: 'Brak dostępu'
                })
              }
              this.$emit(
                action.event,
                event.currentTarget
                  .getElementsByTagName('button')[0]
                  .getAttribute('ref'),
                JSON.parse(buttonParams)
              )
            }
          )
        }
      }
    },
    vehicleTypeColumnExists (item) {
      return item.dataType === 'vehicle'
    },
    dateColumnExists (data) {
      return data.dataType === 'datetime'
    },
    selectRow () {
      if (this.selectable) {
        const vm = this
        $('#grid').on('click', 'tr', function (e) {
          var id = $(e.target.parentElement).attr('data-id')
          var index = $.inArray(id, vm.selected)

          if (index === -1) {
            vm.selected.push(id)
          } else {
            vm.selected.splice(index, 1)
          }
          $(this).toggleClass('active')
          vm.$events.$emit('attachment-selected', vm.selected)
        })
      }
    }
  }
}
</script>

<style scoped>
.table .searcher th > * {
  width: 100%;
  padding: 0px;
}
.centered-flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
