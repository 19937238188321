export default {
  methods: {
    getGridColumns () {
      let vm = this

      const isAppraisal = (field) => field ? 'appraisal' : 'main'
      const setupClassesOnRow = (row, meta) => {
        meta.settings.aoData[meta.row].nTr.classList = `${isAppraisal(row.parentId)}`
      }

      this.gridColumns = [
          {
            id: 0,
            title: 'NR CRLS',
            data: 'taskNumber',
            render (data, type, row, meta) {
              setupClassesOnRow(row, meta)
              return data !== null ? data : row.id
            }
          },
          { id: 1, data: 'assignee', title: 'OPIEKUN', sortable: false },
          { id: 2, data: 'state', title: 'STATUS' },
          {
            id: 3,
            data: 'createdAt',
            title: 'UTWORZONO',
            render (data) {
              return vm.formatDate(data)
            }
          },
          { id: 4, data: 'orderId', title: 'NR BANKU' },
          { id: 5, data: 'address', title: 'ADRES' },
          { id: 6, data: 'localVisionAppointedAt', title: 'DATA WIZJI' },
          { id: 7, data: 'landRegisterNumber', title: 'NR KW' },
          { id: 8, data: 'voivodeship', title: 'WOJEWÓDZTWO' },
          { id: 9, data: 'analyst', title: 'ANALITYK' },
          { id: 10, data: 'orderCompletionAt', title: 'TERMIN REALIZACJI' },
          { id: 11, data: 'closedAt', title: 'PIERWSZE PRZEKAZANIE' },
          { id: 12, data: 'customerFullname', title: 'DANE KLIENTA' },
        ]
    }
  }
}
